<template>
  <b-card>
    <b-card-text>Qui puoi visualittare tutti i vincitori della slot machine</b-card-text>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <div
        v-if="!contentloaded"
      >
        <b-spinner
          variant="primary"
          class="spinner-def"
        />
      </div>
      <div>
        <!-- DISABILITATO MOMENTANEAMENTE -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="absolute-right"
          @click="sendMailsToAll"
        >
          Invia reminder a tutti
        </b-button>
      </div>
      <b-form-group v-if="contentloaded">
        <div class="d-flex align-items-center">
          <b-form-input
            v-model="searchTerm"
            placeholder="Cerca"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      v-if="contentloaded"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <span v-if="props.column.field === 'action' && !props.formattedRow['date_used']">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                @click="sendReminder(props.formattedRow['email'])"
              >
                <feather-icon
                  icon="SendIcon"
                  class="mr-50"
                />
                <span>Invia reminder</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else-if="props.column.field !== 'date_deadline' && props.column.field !== 'date_win' && props.column.field !== 'date_used' && props.column.field !== 'prize'">
          {{ props.formattedRow[props.column.field] }}
        </span>
        <span v-else-if="props.column.field !== 'prize'">
          {{ getDate(props.formattedRow[props.column.field]) }}
        </span>
        <span v-if="props.column.field === 'prize'">
          {{ getPrize(props.formattedRow['prize']) }}
        </span>
      </template>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostra
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['15','30','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> per volta </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BSpinner, BButton, BCard, BCardText, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BCardText,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BSpinner,
    BDropdownItem,
  },
  data() {
    return {
      contentloaded: false,
      pageLength: 15,
      dir: false,
      columns: [
        {
          label: 'Nome e cognome',
          field: 'fullname',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Cap',
          field: 'cap',
        },
        {
          label: 'Premio',
          field: 'prize',
        },
        {
          label: 'Data vincita',
          field: 'date_win',
        },
        {
          label: 'Data scadenza',
          field: 'date_deadline',
        },
        {
          label: 'Data utilizzo',
          field: 'date_used',
        },
        {
          label: 'Azioni',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.getWinners()
  },
  methods: {
    getPrize(prize) {
      let x = ''
      switch (prize) {
        case 1:
          x = '10% DI SCONTO'
          break
        case 2:
          x = '15% DI SCONTO'
          break
        case 3:
          x = 'OMAGGIO'
          break
        case 4:
          x = 'PROSECCO'
          break
        case 5:
          x = 'BUONO 2X1'
          break
        default:
          x = 'ERRORE'
      }
      return x
    },
    sendMailsToAll() {
      /*
      this.$http.post('/slotmachine/reminder').then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sto inviando le mail in background a tutti gli utenti con Mailgun...',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Impossibile inviare le mail!',
            icon: 'AlertCircleIcon',
            variant: 'error',
          },
        })
      })
      */
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Disabilitato momentaneamente!',
          icon: 'AlertCircleIcon',
          variant: 'info',
        },
      })
    },
    sendReminder(email) {
      this.$http.post(`/slotmachine/reminder/${email}`).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Email inviata correttamente.',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Impossibile inviare email.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
    },
    getDate(date) {
      if (date !== null && date !== undefined) {
        if (date.trim() !== '') {
          return window.moment(date).format('DD/MM/YYYY')
        }
      }
      return '-'
    },
    getWinners() {
      this.$http.get('/slotmachine/winners').then(response => {
        this.rows = response.data
        this.contentloaded = true
      }).catch(() => {
        this.contentloaded = true
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Errore nel recupero dei vincitori.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
    },
  },
}
</script>

<style>
  .spinner-def{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .absolute-right{
    position: absolute;
    right: 20px;
    top:20px;
  }
</style>
